var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "assessment-graphic",
      class: { scaled: _vm.scaled },
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "area-labelledby": "assessment-icon-title",
        role: "presentation",
        viewBox: "0 0 214 64"
      }
    },
    [
      _c("title", { attrs: { id: "assessment-icon-title", lang: "en" } }, [
        _vm._v("\n        Starling Assessment\n    ")
      ]),
      _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
        _c("path", {
          attrs: {
            stroke: "#3bc6eb",
            "stroke-width": "4",
            d:
              "M12.6256905 56.6642408c16.2355173 0 23.8606525-24.272639 38.8875594-24.272639M62.0933702 32.3916197c15.2725887 0 23.2848752 12.070173 38.9775528 12.070173M111.651061 44.4617027c19.03773 0 19.690002-36.5213628 38.61884-36.5213628M160.850039 7.94025c13.742162 0 22.848587 12.31496 39.066286 12.31496",
            "stroke-linecap": "round",
            "stroke-linejoin": "round"
          }
        }),
        _c("path", {
          attrs: {
            fill: "#ffffff",
            d:
              "M13.5801563 56.2901052c0 2.9216082-2.3684446 5.2901052-5.2899882 5.2901052C5.3684447 61.5802104 3 59.2117134 3 56.2901052S5.3684446 51 8.290168 51c2.9215437 0 5.2899883 2.368497 5.2899883 5.2901052"
          }
        }),
        _c("path", {
          attrs: {
            stroke: "#a045ae",
            "stroke-width": "4",
            d:
              "M14 56c0 3.3136674-2.686287 6-5.999898 6C4.686287 62 2 59.3136674 2 56s2.686287-6 6.000102-6C11.3137133 50 14 52.6863326 14 56z",
            "stroke-linecap": "round",
            "stroke-linejoin": "round"
          }
        }),
        _c("path", {
          attrs: {
            fill: "#ffffff",
            d:
              "M63.5801563 32.2901052c0 2.9216082-2.3684446 5.2901052-5.2899882 5.2901052-2.9217234 0-5.290168-2.368497-5.290168-5.2901052S55.3684446 27 58.290168 27c2.9215437 0 5.2899883 2.368497 5.2899883 5.2901052"
          }
        }),
        _c("path", {
          attrs: {
            stroke: "#ffb729",
            "stroke-width": "4",
            d:
              "M64 32c0 3.3136674-2.686287 6-5.999898 6C54.686287 38 52 35.3136674 52 32s2.686287-6 6.000102-6C61.313713 26 64 28.6863326 64 32z",
            "stroke-linecap": "round",
            "stroke-linejoin": "round"
          }
        }),
        _c("path", {
          attrs: {
            fill: "#ffffff",
            d:
              "M112.580156 44.2901052c0 2.9216082-2.368444 5.2901052-5.289988 5.2901052-2.921723 0-5.290168-2.368497-5.290168-5.2901052S104.368445 39 107.290168 39c2.921544 0 5.289988 2.368497 5.289988 5.2901052"
          }
        }),
        _c("path", {
          attrs: {
            stroke: "#ff8884",
            "stroke-width": "4",
            d:
              "M113 44c0 3.3136674-2.686287 6-5.999898 6C103.686287 50 101 47.3136674 101 44s2.686287-6 6.000102-6C110.313713 38 113 40.6863326 113 44z",
            "stroke-linecap": "round",
            "stroke-linejoin": "round"
          }
        }),
        _c("path", {
          attrs: {
            fill: "#ffffff",
            d:
              "M161.580156 8.2901052c0 2.9216082-2.368444 5.2901052-5.289988 5.2901052-2.921723 0-5.290168-2.368497-5.290168-5.2901052S153.368445 3 156.290168 3c2.921544 0 5.289988 2.368497 5.289988 5.2901052"
          }
        }),
        _c("path", {
          attrs: {
            stroke: "#1895c6",
            "stroke-width": "4",
            d:
              "M162 8c0 3.3136675-2.686287 6-5.999898 6C152.686287 14 150 11.3136675 150 8s2.686287-6 6.000102-6C159.313713 2 162 4.6863325 162 8z",
            "stroke-linecap": "round",
            "stroke-linejoin": "round"
          }
        }),
        _c("path", {
          attrs: {
            fill: "#ffffff",
            d:
              "M211.580156 20.2901052c0 2.9216082-2.368444 5.2901052-5.289988 5.2901052-2.921723 0-5.290168-2.368497-5.290168-5.2901052S203.368445 15 206.290168 15c2.921544 0 5.289988 2.368497 5.289988 5.2901052"
          }
        }),
        _c("path", {
          attrs: {
            stroke: "#53cc78",
            "stroke-width": "4",
            d:
              "M212 20c0 3.3136674-2.686287 6-5.999898 6C202.686287 26 200 23.3136674 200 20s2.686287-6 6.000102-6C209.313713 14 212 16.6863326 212 20z",
            "stroke-linecap": "round",
            "stroke-linejoin": "round"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }